import Vue from "vue"
import Vuex from "vuex"
import { cityModule } from "./modules/city"
import { newsModule } from "./modules/news"
import { stockModule } from "./modules/stock"
import { catalogModule } from "./modules/catalog"
import { searchModule } from "./modules/search"
import { vacancyModule } from "./modules/vacancy"
import { categoriesModule } from "./modules/categories"
import { recipesModule } from "./modules/recipes"

Vue.use(Vuex)

export const mutationTypes = {
  setIsOpenMenu: "mutation/setIsOpenMenu",
  setIsOpenSelectCity: "mutation/setIsOpenSelectCity",

  setCurrentLanguage: "mutation/setCurrentLanguage",

  // isAuthActive modal
  setIsAuthActive: "mutation/setIsAuthActive",
  setOpenFilter: "mutation/setOpenFilter",

  setIsOpenHiddenModal: "mutation/setIsOpenHiddenModal",
  openSearchingModal: "mutation/openSearchingModal",
}
export const actionTypes = {
  changeIsOpenMenu: "action/changeIsOpenMenu",
  changeIsOpenSelectCity: "action/changeIsOpenSelectCity",
  changeIsCloseMenu: "action/changeIsCloseMenu",

  changeCurrentLanguage: "action/changeCurrentLanguage",

  // isAuthActive modal
  changeIsAuthActive: "action/changeIsAuthActive",
  changeOpenFilter: "action/changeOpenFilter",

  getIsOpenHiddenModal: "action/getIsOpenHiddenModal",
}

export default new Vuex.Store({
  state: {
    isOpenMenu: false,
    isOpenSelectCity: false,
    currentLanguage: null,
    isAuthActive: null,
    isFilterActive: false,
    isOpenHiddenModal: false,
    isSearchingOpen: false,
  },
  mutations: {
    [mutationTypes.setIsOpenMenu](state) {
      state.isOpenMenu = !state.isOpenMenu
      if (!state.isOpenMenu) {
        document.querySelector("body").classList.remove("no-scroll")
      }
    },
    [mutationTypes.setIsOpenSelectCity](state) {
      state.isOpenSelectCity = !state.isOpenSelectCity
    },
    [mutationTypes.setCurrentLanguage](state, payload) {
      state.currentLanguage = payload
    },

    [mutationTypes.setIsAuthActive](state, payload) {
      if (state.isAuthActive) {
        state.isAuthActive = false
      } else {
        state.isAuthActive = payload.page ? payload : true
      }
    },

    [mutationTypes.setOpenFilter](state) {
      state.isFilterActive = !state.isFilterActive
    },

    [mutationTypes.setIsOpenHiddenModal](state, payload) {
      state.isOpenHiddenModal = payload
    },
    [mutationTypes.openSearchingModal](state, payload) {
      state.isSearchingOpen = payload
    },
  },
  actions: {
    [actionTypes.changeIsOpenMenu]({ commit }) {
      commit(mutationTypes.setIsOpenMenu)
    },
    [actionTypes.changeIsOpenSelectCity]({ commit }) {
      commit(mutationTypes.setIsOpenSelectCity)
    },
    [actionTypes.changeIsCloseMenu]({ commit }) {
      commit(mutationTypes.setIsOpenMenu)
    },
    [actionTypes.changeCurrentLanguage]({ commit }, lang) {
      commit(mutationTypes.setCurrentLanguage, lang)
    },

    [actionTypes.changeIsAuthActive]({ commit }, payload) {
      commit(mutationTypes.setIsAuthActive, payload)
    },

    [actionTypes.changeOpenFilter]({ commit }) {
      commit(mutationTypes.setOpenFilter)
    },
    [actionTypes.getIsOpenHiddenModal]({ commit }, payload) {
      commit(mutationTypes.setIsOpenHiddenModal, payload)
    },
  },
  modules: {
    cityModule,
    newsModule,
    stockModule,
    catalogModule,
    searchModule,
    vacancyModule,
    categoriesModule,
    recipesModule,
  },
  // plugins: [vueLocalStorage.plugin],
})
