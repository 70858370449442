<template>
  <router-link
    :to="{ path: getTo(), query: getQuery() }"
    :tag="tag"
  >
    <slot />
  </router-link>
</template>

<script>
export default {
  props: {
    to: {
      require: true,
    },
    tag: {
      type: String,
      default: "a",
    },
    query: {
      type: Object,
      required: false,
    },
  },
  methods: {
    getTo() {
      const locale = this.$i18n.locale

      if (typeof this.to !== "string") {
        // this.to.path = `${locale}/${this.to.path}`;
        return this.to
      }
      return `/${locale}/${this.to.replace(/^\/|\/$/g, "")}`
    },
    getQuery() {
      if (this.query) {
        return { city_id: this.$route.query.city_id, ...this.query }
      } else {
        return { city_id: this.$route.query.city_id }
      }
    },
  },
}
</script>
