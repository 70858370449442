export const mutationTypes = {
  setRecipesFilters: "mutation/setRecipesFilters",
}
export const actionTypes = {
  getRecipesFilters: "action/getRecipesFilters",
}

export const recipesModule = {
  namespaced: true,
  state: {
    filters: [],
  },
  mutations: {
    [mutationTypes.setRecipesFilters](state, payload) {
      state.filters = payload
    },
  },
  actions: {
    [actionTypes.getRecipesFilters]({ commit }, payload) {
      commit(mutationTypes.setRecipesFilters, payload)
    },
  },
}
