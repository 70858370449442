import { getProductsByFilters } from "@/api/products"

export const mutationTypes = {
  addFilter: "mutation/addFilter",
  setFilters: "mutation/setFilters",
  removeFilter: "mutation/removeFilter",
  clearFilters: "mutation/clearFilters",
  clearCategory: "mutation/clearCategory",
  clearSubcategoryFilters: "mutation/clearSubcategoryFilters",

  addSortBy: "mutation/addSortBy",
  clearSortBy: "mutation/clearSortBy",

  loadProductsStart: "mutation/loadProductsStart",
  loadProductsSuccess: "mutation/loadProductsSuccess",
  loadProductsFailure: "mutation/loadProductsFailure",

  setCategories: "mutation/setCategories",
  setSubcategories: "mutation/setSubcategories",

  setMenu: "mutation/setMenu",
}
export const actionTypes = {
  actionLoadProductsSuccess: "action/actionLoadProductsSuccess",
  actionLoadProductsFailure: "action/actionLoadProductsFailure",
  changeFilter: "action/changeFilter",
  replaceFilters: "action/replaceFilters",
  changeSortBy: "action/changeSortBy",
  clearFilters: "action/clearFilters",
  clearCategory: "action/clearCategory",
  clearSubcategoryFilters: "action/clearSubcategoryFilters",
  getFiltredProducts: "action/getFiltredProducts",

  getCategories: "action/getCategories",
  getSubcategories: "action/getSubcategories",

  getMenu: "mutation/getMenu",
}

export const catalogModule = {
  namespaced: true,
  state: () => ({
    data: [],
    meta: [],
    menu: [],
    filters: {
      subcategories: [],
      countries: [],
      sugar: [],
      volume: [],
      brand: [],
      vinePlace: [],
      strenght: [],
      shopTypes: [],
      discountTypes: [],
    },
    sortBy: null,
    error: null,
    isLoading: false,
    categories: [],
    dataData: [
      {
        subcategories: [],
        filters: [
          {
            type: "sugar",
            data: ["сахар"],
          },
        ],
      },
    ],
  }),

  mutations: {
    [mutationTypes.addFilter](state, payload) {
      switch (payload.type) {
        case "subcategories":
          state.filters.subcategories.push(payload.filter)
          return
        case "countries":
          state.filters.countries.push(payload.filter)
          return
        case "sugar":
          state.filters.sugar.push(payload.filter)
          return
        case "volume":
          state.filters.volume.push(payload.filter)
          return
        case "brand":
          state.filters.brand.push(payload.filter)
          return
        case "vinePlace":
          state.filters.vinePlace.push(payload.filter)
          return
        case "strenght":
          state.filters.strenght.push(payload.filter)
          return
        case "shopTypes":
          state.filters.shopTypes.push(payload.filter)
          return
        case "discountTypes":
          state.filters.discountTypes.push(payload.filter)
          return
      }
    },

    [mutationTypes.setFilters](state, payload) {
      state.filters = payload
    },

    [mutationTypes.removeFilter](state, payload) {
      switch (payload.type) {
        case "subcategories":
          state.filters.subcategories.splice(payload.foundFilterIndex, 1)
          return
        case "countries":
          state.filters.countries.splice(payload.foundFilterIndex, 1)
          return
        case "sugar":
          state.filters.sugar.splice(payload.foundFilterIndex, 1)
          return
        case "volume":
          state.filters.volume.splice(payload.foundFilterIndex, 1)
          return
        case "brand":
          state.filters.brand.splice(payload.foundFilterIndex, 1)
          return
        case "vinePlace":
          state.filters.vinePlace.splice(payload.foundFilterIndex, 1)
          return
        case "strenght":
          state.filters.strenght.splice(payload.foundFilterIndex, 1)
          return
        case "shopTypes":
          state.filters.shopTypes.splice(payload.foundFilterIndex, 1)
          return
        case "discountTypes":
          state.filters.discountTypes.splice(payload.foundFilterIndex, 1)
          return
      }
    },

    [mutationTypes.clearFilters](state) {
      state.filters.countries = []
      state.filters.sugar = []
      state.filters.brand = []
      state.filters.volume = []
      state.filters.vinePlace = []
      state.filters.strenght = []
      state.filters.shopTypes = []
      state.filters.discountTypes = []
      state.filters.subcategories = []
    },
    [mutationTypes.clearCategory](state) {
      state.filters.subcategories = []
    },

    [mutationTypes.clearSubcategoryFilters](state) {
      state.filters.subcategories = []
    },
    [mutationTypes.addSortBy](state, payload) {
      state.sortBy = payload
    },
    [mutationTypes.clearSortBy](state) {
      state.sortBy = null
    },
    [mutationTypes.clearSortBy](state) {
      state.sortBy = null
    },

    [mutationTypes.loadProductsStart](state) {
      state.isLoading = true
      state.error = null
    },

    [mutationTypes.loadProductsSuccess](state, payload) {
      state.isLoading = false
      state.error = null
      state.meta = payload.data.meta

      switch (payload.type) {
        case "push":
          // state.data = [...state.data, ...payload.data.data]
          state.data = payload.data.data
          return
        case "replace":
          state.data = payload.data.data
          return
        case "next":
          state.data = [...state.data, ...payload.data.data]
          return
      }
    },

    [mutationTypes.loadProductsFailure](state, payload) {
      state.isLoading = false
      state.data = []
      state.error = payload.data.data
    },

    [mutationTypes.setCategories](state, payload) {
      state.categories = payload
    },

    [mutationTypes.setSubcategories](state, payload) {
      state.subcategories = payload
    },

    [mutationTypes.setMenu](state, payload) {
      state.menu = payload
    },
  },
  actions: {
    [actionTypes.changeFilter]({ commit, state }, { type, filter }) {
      const foundFilterIndex = state.filters[type].findIndex((el) => el === filter)
      if (foundFilterIndex === -1) {
        commit(mutationTypes.addFilter, { type, filter })
        return
      }
      commit(mutationTypes.removeFilter, { type, foundFilterIndex })
    },
    [actionTypes.replaceFilters]({ commit }, payload) {
      commit(mutationTypes.setFilters, payload)
    },
    [actionTypes.clearFilters]({ commit }) {
      commit(mutationTypes.clearFilters)
    },
    [actionTypes.clearCategory]({ commit }) {
      commit(mutationTypes.clearCategory)
    },
    [actionTypes.clearSubcategoryFilters]({ commit }) {
      commit(mutationTypes.clearSubcategoryFilters)
    },
    [actionTypes.actionLoadProductsSuccess]({ commit }, payload) {
      if (payload?.isPaginate) {
        commit(mutationTypes.loadProductsSuccess, {
          type: "push",
          data: payload?.res,
        })
      } else {
        commit(mutationTypes.loadProductsSuccess, {
          type: "replace",
          data: payload?.res,
        })
      }
    },
    [actionTypes.actionLoadProductsFailure]({ commit }) {
      commit(mutationTypes.loadProductsFailure)
    },
    [actionTypes.getFiltredProducts]({ commit, state, rootState }, payload) {
      commit(mutationTypes.loadProductsStart)
      return new Promise((resolve) => {
        getProductsByFilters(
          state.filters,
          state.sortBy,
          payload?.pagination,
          null,
          payload?.cityId || rootState.cityModule.selectedCity?.id || 2,
        )
          .then((response) => {
            console.log("isNextPage", payload?.isNextPage)
            if (payload?.isPaginate) {
              commit(mutationTypes.loadProductsSuccess, {
                type: "push",
                data: response,
              })
            } else if (payload?.isNextPage) {
              commit(mutationTypes.loadProductsSuccess, {
                type: "next",
                data: response,
              })
            } else {
              commit(mutationTypes.loadProductsSuccess, {
                type: "replace",
                data: response,
              })
            }

            resolve(response)
          })
          .catch(() => {
            commit(mutationTypes.loadProductsFailure)
          })
      })
    },

    [actionTypes.changeSortBy]({ commit }, payload) {
      commit(mutationTypes.addSortBy, payload)
    },

    [actionTypes.getMenu]({ commit }, payload) {
      commit(mutationTypes.setMenu, payload)
    },

    [actionTypes.getCategories]({ commit }, payload) {
      commit(mutationTypes.setCategories, payload)
    },

    [actionTypes.getSubcategories]({ commit }, payload) {
      commit(mutationTypes.setSubcategories, payload)
    },
  },
  getters: {
    isFilterEmpty: (state) => {
      const { categories, shopTypes, discountTypes } = state.filters

      return categories?.length || shopTypes?.length || discountTypes?.length
    },
  },
}
