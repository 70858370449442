import axios from "@/plugins/axios"
import qs from "qs"

export const getRule = async (page = 1, pageSize = 100) => {
  const query = qs.stringify(
    {
      sort: ["id:asc"],
      pagination: {
        page,
        pageSize,
      },
      populate: "*",
    },
    {
      encodeValuesOnly: true,
    },
  )
  const response = await axios.get(`/rule?${query}`)
  return response?.data
}
