<template>
  <div>
    <app-header
      :key="'header-' + $i18n.locale"
      :fixed="isFixed"
    />
    <app-menu :ruleFile="getRuleFile" />
    <cities-list :key="$i18n.locale" />
    <app-modal-age v-if="!isAdult" />
    <app-modal-hidden
      v-if="isOpenHiddenModal"
      @close="getIsOpenHiddenModal(false)"
    />
    <app-modal-registration
      v-if="isAuthActive"
      :ruleFile="getRuleFile"
    />
    <div
      class="default-wrapper"
      style="min-height: 50vh"
    >
      <slot />
    </div>
    <app-footer :ruleFile="getRuleFile" />
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex"
import { getRule } from "@/api/rules"
import { actionTypes } from "@/store"
import { actionTypes as actionTypesCity } from "@/store/modules/city"
import { actionTypes as actionTypesCatalog } from "@/store/modules/catalog"
import AppModalAge from "@/components/modal/AppModalAge.vue"

export default {
  name: "DefaultLayout",
  components: {
    AppMenu: () => import("@/components/common/AppMenu.vue"),
    AppHeader: () => import("@/components/common/AppHeader.vue"),
    AppFooter: () => import("@/components/common/AppFooter.vue"),
    CitiesList: () => import("@/components/common/CitiesList.vue"),
    AppModalRegistration: () => import("@/components/modal/AppModalRegistration.vue"),
    AppModalHidden: () => import("@/components/modal/AppModalHidden.vue"),
    AppModalAge,
  },

  data() {
    return {
      isFixed: false,
      isAdult: false,
      rulesData: {},
    }
  },

  computed: {
    ...mapState(["isOpenSelectCity", "isAuthActive", "isOpenHiddenModal"]),
    ...mapState("cityModule", { cities: "data", selectedCity: "selectedCity" }),
    getRuleFile() {
      if (this.rulesData?.attributes?.file) {
        return this.$helpers.getAbsolutePath(
          this.rulesData?.attributes?.file?.data?.attributes?.url,
        )
      }
      return ""
    },
  },

  watch: {
    isOpenSelectCity: {
      handler() {
        if (this.isOpenSelectCity) {
          document.querySelector("body").classList.add("no-scroll")
        } else {
          document.querySelector("body").classList.remove("no-scroll")
        }
      },
    },
    // "$route.name": {
    //   handler() {
    //     if (this.$route.name == "catalog") {
    //       this.clearFilters()
    //     }
    //   },
    // },
  },

  mounted() {
    if (localStorage.getItem("selectCity")) {
      this.changeSelectedCity(JSON.parse(localStorage.getItem("selectCity")))
    }
    if (localStorage.getItem("lang")) {
      this.$i18n.locale = localStorage.getItem("lang")
    }
    this.$router.onReady(() => {
      if (this.$route.params.lang !== this.$i18n.locale) {
        this.$router.replace({
          query: { city_id: this.selectedCity?.id || 3 },
        })
      }
    })
    if (localStorage.getItem("isAdult")) {
      this.isAdult = true
    }
    this.loadRules()
  },

  methods: {
    ...mapActions({
      changeLang: actionTypes.changeCurrentLanguage,
      getIsOpenHiddenModal: actionTypes.getIsOpenHiddenModal,
    }),
    ...mapActions("cityModule", {
      changeSelectedCity: actionTypesCity.changeSelectedCity,
    }),
    ...mapActions("catalogModule", {
      clearFilters: actionTypesCatalog.clearFilters,
    }),
    changeIsFixed() {
      this.isFixed = true
    },
    async loadRules() {
      const response = await getRule()
      this.rulesData = response.data
    },
  },
}
</script>
