<template>
  <div
    v-if="!close"
    class="modal"
  >
    <div class="modal__container">
      <div class="modal__age">
        <div class="container">
          <div class="modal__age-content">
            <div class="modal__age-logo">
              <img
                src="@/assets/img/icons/logo.svg"
                alt=""
              />
            </div>
            <h3 class="modal__age-title">Пожалуйста, введите дату своего рождения</h3>
            <p class="modal__age-text">
              Информация на сайте доступна только для лиц старше 21 года
            </p>
            <p
              v-if="isNotAdult"
              class="modal__age-text adult"
            >
              Ваш возраст меньше 21 года
            </p>
            <p
              v-if="isDayError"
              class="modal__age-text adult"
            >
              Неверно введен день
            </p>
            <p
              v-if="isMonthError"
              class="modal__age-text adult"
            >
              Неверно введен месяц
            </p>
          </div>

          <form class="modal__age-form">
            <div class="modal__age-date">
              <input
                class="modal__age-input day"
                type="text"
                v-model="age.day"
                v-mask="'##'"
                @keyup="addInput"
                placeholder="дд"
                required
              />

              <input
                class="modal__age-input month"
                type="text"
                v-model="age.month"
                v-mask="'##'"
                placeholder="мм"
                @keyup="addInput"
                required
              />
              <input
                class="modal__age-input year"
                type="text"
                v-model="age.year"
                v-mask="'####'"
                placeholder="гггг"
                @keyup="addInput"
                required
              />
            </div>
            <p
              v-if="isNotAdult && $isMobile()"
              class="modal__age-text adult mobile"
            >
              Ваш возраст меньше 21 года
            </p>
            <div class="modal__age-checkboxes">
              <div class="modal__age-checkbox">
                <input
                  id="1"
                  type="checkbox"
                  v-model="isCheckRemember"
                  required
                />
                <label for="1">Запомнить меня на этом устройстве</label>
              </div>
              <div class="modal__age-checkbox">
                <input
                  id="2"
                  type="checkbox"
                  v-model="isCheckConfirm"
                  required
                />
                <label for="2"
                  >Я подтверждаю, что мой возраст больше 21 года. Введенные мной данные
                  достоверны</label
                >
              </div>
            </div>
            <button
              class="modal__age-btn"
              @click.prevent="getAge"
              :class="{ 'is-disabled': !isDisabled }"
              :disabled="!isDisabled"
            >
              Войти на сайт
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AppModalAge",
  data() {
    return {
      close: false,
      isNotAdult: false,
      isCheckRemember: false,
      isCheckConfirm: false,
      isDayError: false,
      isMonthError: false,
      age: {
        day: "",
        month: "",
        year: "",
      },
    }
  },
  computed: {
    isDisabled() {
      return (
        this.age.day !== "" && this.age.month !== "" && this.age.year !== "" && this.isCheckConfirm
      )
    },
  },
  methods: {
    giveAdultRights() {
      localStorage.setItem("isAdult", true)
      this.close = true
    },
    getCurrentAge(data) {
      var now = new Date() //Текущя дата
      var today = new Date(now.getFullYear(), now.getMonth(), now.getDate()) //Текущя дата без времени
      var dob = new Date(data[0], data[1], data[2]) //Дата рождения
      var dobnow = new Date(today.getFullYear(), dob.getMonth(), dob.getDate()) //ДР в текущем году
      var age //Возраст

      //Возраст = текущий год - год рождения
      age = today.getFullYear() - dob.getFullYear()
      //Если ДР в этом году ещё предстоит, то вычитаем из age один год
      if (today < dobnow) {
        age = age - 1
      }

      return age
    },
    get_current_age(date) {
      var d = date.split(".")
      console.log("lol", (new Date().getTime() - new Date(date)) / (24 * 3600 * 365.25 * 1000))
      if (Number(d[2]) < 1900) {
        return 0
      }
      if (typeof d[2] !== "undefined") {
        date = d[2] + "." + d[1] + "." + d[0]
        return ((new Date().getTime() - new Date(date)) / (24 * 3600 * 365.25 * 1000)) | 0
      }
      return 0
    },
    getAge() {
      if (this.age.day > 31 && this.age.month > 12) {
        this.isDayError = true
        this.isMonthError = true
        return
      } else if (this.age.day > 31) {
        this.isDayError = true
        this.isMonthError = false
        return
      } else if (this.age.month > 12) {
        this.isDayError = false
        this.isMonthError = true
        return
      } else {
        this.isDayError = false
        this.isMonthError = false
      }
      let age = this.getCurrentAge([this.age.year, this.age.month, this.age.day])
      if (age >= 21) {
        if (this.isCheckRemember) {
          localStorage.setItem("isAdult", true)
        }
        this.isNotAdult = false
        this.close = true
      } else {
        this.isNotAdult = true
      }
    },
    addInput(e) {
      if (!isNaN(Number(e.target.value)) && e.target.value.length !== 0) {
        switch (e.target.classList[1]) {
          case "day":
            if (this.age.day.length === 2) e.target.nextElementSibling.focus()
            break
          case "month":
            if (this.age.month.length === 2) e.target.nextElementSibling.focus()
            break
        }
      } else if (e.key === "Backspace") {
        if (e.target.previousElementSibling) {
          e.target.previousElementSibling.focus()
        }
      }

      // console.log(this.age[date].length)
    },
  },
}
</script>

<style
  lang="scss"
  scoped
>
.modal__age__answer {
  .bigbutton {
    margin: 0;
    width: 150px;
    background: linear-gradient(270.64deg, #8344d6 3.8%, #9c52fc 96.87%);
    box-shadow: 0px 4px 20px rgba(55, 0, 128, 0.2);
    border-radius: 10px;
    color: #fefefe;
  }
}
</style>
