import Vue from "vue"
import App from "./App.vue"
import router from "./router"
import store from "./store"
import VueMobileDetection from "vue-mobile-detection"
import "./assets/sass/style.sass"
import DefaultLayout from "@/layouts/DefaultLayout.vue"
import getAwesomeSwiper from "@/plugins/vue-awesome-swiper"
// import Vue from 'vue'
import VueCookies from "vue-cookies"
import "@/plugins/vue-validate"
import VueLazyload from "vue-lazyload"
import helpers from "@/helpers"
import i18n from "./plugins/i18n"
import VueMask from "v-mask"
import "aos/dist/aos.css"
import AOS from "aos"
const loadimage = require("@/" + "assets/img/icons/img_placeholder.svg")
import LocaleRouterLink from "@/components/language/LocaleRouterLink.vue"
const plugin = {
  install() {
    Vue.helpers = helpers
    Vue.prototype.$helpers = helpers
  },
}
AOS.init({
  once: true,
})
Vue.component("LocaleRouterLink", LocaleRouterLink)
Vue.use(VueLazyload, {
  preLoad: 1.3,
  loading: loadimage,
  attempt: 1,
})
Vue.use(VueMask)
Vue.use(VueCookies)
Vue.use(plugin)
Vue.use(getAwesomeSwiper)
Vue.use(VueMobileDetection)

Vue.component("default-layout", DefaultLayout)
Vue.config.productionTip = false

new Vue({
  i18n,
  router,
  store,
  render: (h) => h(App),
}).$mount("#app")
