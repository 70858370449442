import axios from "@/plugins/axios"
import qs from "qs"

export const getAllProducts = async (page = 1, pageSize = 500, cityId, subcategory) => {
  let queryFilters = { filters: {} }

  if (subcategory) {
    queryFilters.filters.subcategories = {
      $id: {
        $in: subcategory,
      },
    }
  }
  const query = qs.stringify(
    {
      sort: ["id:asc"],
      pagination: {
        page,
        pageSize,
      },
      filters: {
        cities: {
          id: { $eq: cityId },
        },
      },
      populate: {
        image: "*",
        localizations: "*",
      },
    },
    {
      encodeValuesOnly: true,
    },
  )
  const response = await axios.get(`/products?${query}`)
  return response?.data
}

export const getProductsByFilters = async (filters, sortBy, pagination, excludeId, cityId) => {
  const { subcategories, countries, sugar, volume, vinePlace, strenght, brand } = filters

  const selectedCityId = localStorage.getItem("selectCity")
    ? JSON.parse(localStorage.getItem("selectCity"))?.id
    : 2

  let queryFilters = { filters: {} }

  if (excludeId) {
    queryFilters.filters["id"] = {
      $ne: excludeId,
    }
  }
  if (selectedCityId) {
    queryFilters.filters.cities = { id: { $eq: selectedCityId } }
  }

  if (subcategories.length) {
    queryFilters.filters.subcategories = {
      id: {
        $in: subcategories,
      },
    }
  }

  if (!subcategories.length && !cityId) {
    queryFilters.filters.subcategories = {
      id: { $notNull: true },
    }
  }

  if (countries.length) {
    queryFilters.filters.country = {
      $contains: countries,
    }
  }

  if (!countries.length && !cityId) {
    queryFilters.filters.country = {
      $notNull: true,
    }
  }

  if (sugar.length) {
    queryFilters.filters.sugar = {
      $contains: sugar,
    }
  }

  // if (!sugar.length && !cityId) {
  //   queryFilters.filters.sugar = {
  //     $notNull: true,
  //   }
  // }

  if (volume.length) {
    queryFilters.filters.volume = {
      $contains: volume,
    }
  }

  if (!volume.length && !cityId) {
    queryFilters.filters.volume = {
      $notNull: true,
    }
  }

  if (vinePlace.length) {
    queryFilters.filters.vine_place = {
      $contains: vinePlace,
    }
  }

  // if (!vinePlace.length && !cityId) {
  //   queryFilters.filters.vine_place = {
  //     $notNull: true,
  //   }
  // }

  if (strenght.length) {
    queryFilters.filters.strenght = {
      $contains: strenght,
    }
  }

  if (!strenght.length && !cityId) {
    queryFilters.filters.strenght = {
      $notNull: true,
    }
  }

  if (brand.length) {
    queryFilters.filters.brand = {
      $contains: brand,
    }
  }

  if (!brand.length && !cityId) {
    queryFilters.filters.brand = {
      $notNull: true,
    }
  }

  let sort = {}

  if (sortBy) {
    sort = { sort: [`${sortBy.field}:${sortBy.sortType}`] }
    // sort: [`${sortBy.field}:${sortBy.sortType}`],
  } else {
    sort = { sort: ["id:asc"] }
  }

  const query = qs.stringify(
    {
      pagination: {
        page: pagination?.page || 1,
        pageSize: pagination?.pageSize || 1500,
      },
      populate: {
        image: "*",
        localizations: "*",
        category: "*",
      },
      ...sort,
      ...queryFilters,
    },
    {
      encodeValuesOnly: true,
    },
  )

  const response = await axios.get(`/products?${query}`)
  return response?.data
}

export const getProductById = async (id) => {
  const query = qs.stringify(
    {
      populate: {
        image: "*",
        localizations: "*",
        subcategories: "*",
      },
      filters: {
        id: {
          $eq: id,
        },
      },
    },
    {
      encodeValuesOnly: true,
    },
  )
  const response = await axios.get(`/products/${id}?${query}`)
  return response?.data?.data
}

export const getProductsByIds = async (id) => {
  const query = qs.stringify(
    {
      populate: {
        image: "*",
        localizations: "*",
        subcategories: "*",
      },
      filters: {
        id: {
          $in: id,
        },
      },
    },
    {
      encodeValuesOnly: true,
    },
  )
  const response = await axios.get(`/products?${query}`)
  return response?.data?.data
}

export const getShopProducts = async (id, pagination, sortBy) => {
  let sort = {}

  if (sortBy) {
    sort = { sort: [`${sortBy.field}:${sortBy.sortType}`] }
    // sort: [`${sortBy.field}:${sortBy.sortType}`],
  } else {
    sort = { sort: ["id:asc"] }
  }
  const query = qs.stringify({
    populate: {
      image: "*",
      localizations: "*",
    },
    ...sort,
    filters: {
      shops: {
        id: { $eq: id },
      },
    },
    pagination: {
      page: pagination?.page || 1,
      pageSize: pagination?.pageSize || 20,
    },
  })
  const response = await axios.get(`/products?${query}`)
  return response?.data
}

export const getBrandProducts = async (page = 1, pageSize = 500, brand, cityId) => {
  const query = qs.stringify(
    {
      sort: ["id:asc"],
      pagination: {
        page,
        pageSize,
      },
      filters: {
        cities: {
          id: { $eq: cityId },
        },
        brand: {
          $contains: brand,
        },
      },
      populate: {
        image: "*",
        localizations: "*",
      },
    },
    {
      encodeValuesOnly: true,
    },
  )
  const response = await axios.get(`/products?${query}`)
  return response?.data
}

export const getProductsBySubcategory = async (page = 1, pageSize = 500, subcategory, cityId) => {
  const query = qs.stringify(
    {
      sort: ["id:asc"],
      pagination: {
        page,
        pageSize,
      },
      filters: {
        cities: {
          id: { $eq: cityId },
        },
        subcategories: {
          id: { $in: subcategory },
        },
      },
      populate: {
        image: "*",
        localizations: "*",
      },
    },
    {
      encodeValuesOnly: true,
    },
  )
  const response = await axios.get(`/products?${query}`)
  return response?.data
}

export const getStocksProducts = async (id) => {
  const query = qs.stringify({
    populate: {
      image: "*",
      localizations: "*",
    },
    filters: {
      discounts: {
        promos: {
          id: { $eq: id },
        },
      },
    },
  })
  const response = await axios.get(`/products?${query}`)
  return response?.data
}
