export const mutationTypes = {
  setCategories: "mutation/setCategories",
  setCategoriesProducts: "mutation/setCategoriesProducts",
  setSubcategories: "mutation/setSubcategories",
}
export const actionTypes = {
  getCategories: "action/setCategories",
  getCategoriesProducts: "action/getCategoriesProducts",
  getSubcategories: "action/getSubcategories",
}

export const categoriesModule = {
  namespaced: true,
  state: {
    categoriesFiltered: [],
    categoriesProducts: [],
    subcategories: [],
  },
  mutations: {
    [mutationTypes.setCategories](state, payload) {
      state.categoriesFiltered = payload
    },
    [mutationTypes.setCategoriesProducts](state, payload) {
      state.categoriesProducts = payload
    },
    [mutationTypes.setSubcategories](state, payload) {
      state.subcategories = payload
    },
  },
  actions: {
    [actionTypes.getCategoriesProducts]({ commit }, payload) {
      commit(mutationTypes.setCategoriesProducts, payload)
    },
    [actionTypes.getCategories]({ commit }, payload) {
      commit(mutationTypes.setCategories, payload)
    },
    [actionTypes.getSubcategories]({ commit }, payload) {
      commit(mutationTypes.setSubcategories, payload)
    },
  },
}
